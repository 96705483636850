import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import Button from '../../common/button/Button';
import Header from '../../common/header/Header';


const Login = () => {
  const formRef = useRef(null);
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
  const [voterId, setVoterId] = useState(null);

  //Generates unique ID
  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');

    if (storedUserId) {
      setUserId(storedUserId);
      console.log("USER ID RECEIVED: ", storedUserId);
      setIsNextButtonDisabled(true);
    } else {
      const newUserId = v4();
      localStorage.setItem('userId', newUserId);
      setUserId(newUserId);
      console.log("USER ID SET:", newUserId);
      setIsNextButtonDisabled(false);
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const isFormValid = formRef.current.checkValidity();

    if (isFormValid) {
      console.log('Form is valid!');

      navigate(`${process.env.REACT_APP_HOMEPAGE}categories`, { state: { voterId } });
      formRef.current.reset();
    } else {
      console.log('Form is not valid');
    };
  };

  return (
    <div className='flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8'>
      <div className='sm:mx-auto sm:w-full sm:max-w-sm md:max-w-2xl'>
        <Header width='max-w-xs' />
        <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm md:max-w-2xl'>
          <form action='#' method='POST' className='space-y-6' ref={formRef} onSubmit={handleSubmit}>
            <div>
              <label htmlFor='IdNo' className='block text-xl'>
                Enter ID Number / Passport
              </label>
              <div className='mt-2'>
                <input
                  ref={inputRef}
                  id='IdNo'
                  name='IdNo'
                  required
                  minLength={8}
                  maxLength={13}
                  onChange={(event) => setVoterId(event.target.value)}
                  autoComplete='number'
                  placeholder='ID Number / Passport'
                  className='fontLight block w-full px-2 pb-2 text-base text-black border-b-2 border-[#CFCFCF] placeholder:text-[#CFCFCF] placeholder:text-base focus:outline-none'
                />
              </div>
            </div>
            <Button
              bgColor='bg-gradient-to-r from-[#00BBD3] from-44% to-[#53BC7F] to-100%'
              shadow='shadow-[1px_5px_13px_3px_rgba(11,133,149,0.46)]'
              padding='px-3 py-3'
              color='text-white'
              width='w-full'
              fontSize='text-xl'
            >
              Next
            </Button>
          </form>
        </div>
      </div>
    </div>
  )
};

export default Login