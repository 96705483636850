import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BottomSheet } from 'react-spring-bottom-sheet';
import Header from '../../common/header/Header';
import CourageLogo from '../../assets/Courage.png';
import IntegrityLogo from '../../assets/Integrity.png';
import InnovationLogo from '../../assets/Innovation.png';
import ServiceLogo from '../../assets/Service.png';
import PartnershipLogo from '../../assets/Partnership.png';
import moment from 'moment';
import axios from 'axios';

const Categories = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [votedCategories, setVotedCategories] = useState([]);
  const voterId = location.state.voterId;
  const [selectedDetails, setSelectedDetails] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams({
      voterId,
      date: moment(new Date()).format('YYYY-MM-DD'),
    }).toString();

    axios.get(`${process.env.REACT_APP_URL}/voterData?${queryParams}`)
    .then(response => {
      if (response.data.status === 'Success') {
        setVotedCategories(response.data.data)
      }
    })
    .catch(error => console.log(error));
  }, []);

  const categories = [
    {
      id: 'courage',
      image: CourageLogo,
      name: 'Courage',
      desc: 'The Brave',
      bgColor: 'bg-gradient-to-r from-[#12AFBE] from-50% to-[#1196A3] to-100%',
      shadow: 'shadow-[0px_5px_21px_3px_rgba(18,175,190,0.47)]'
    },
    {
      id: 'integrity',
      image: IntegrityLogo,
      name: 'Integrity',
      desc: 'The Honest',
      bgColor: 'bg-gradient-to-r from-[#0171BB] from-50% to-[#005C98] to-100%',
      shadow: 'shadow-[0px_5px_21px_3px_rgba(2,114,188,0.54)]'
    },
    {
      id: 'innovation',
      image: InnovationLogo,
      name: 'Innovation',
      desc: 'The Curious',
      bgColor: 'bg-gradient-to-r from-[#0F8342] from-50% to-[#0B6533] to-100%',
      shadow: 'shadow-[0px_5px_21px_3px_rgba(15,131,66,0.4)]'
    },
    {
      id: 'service',
      image: ServiceLogo,
      name: 'Service',
      desc: 'The Selfless',
      bgColor: 'bg-gradient-to-r from-[#FDB415] from-50% to-[#C78800] to-100%',
      shadow: 'shadow-[0_5px_21px_3px_rgba(193,132,0,0.4)]'
    },
    {
      id: 'partnership',
      image: PartnershipLogo,
      name: 'Partnership',
      desc: 'The Trustworthy',
      bgColor: 'bg-gradient-to-r from-[#D02127] from-50% to-[#A20000] to-100%',
      shadow: 'shadow-[0px_5px_21px_3px_rgba(169,22,27,0.4)]'
    }
  ];

  // Function to check if a category btn should be disabled
  const isDisabled = (category) => {
    return votedCategories.some(votedCategoryItem => votedCategoryItem.category === category.id);
  };

  const handleClick = (category) => {
    if (isDisabled(category)) {
      const details = votedCategories.find(detail => detail.category === category.id);
      setSelectedDetails(details);
      setOpen(true);
      return;
    }

    navigate(`${process.env.REACT_APP_HOMEPAGE}employerDetails`,
      {
        state: {
          selectedCategory: category.id,
          voterId,
        }
      }
    );
  };

  // Function to close the modal
  const closeBottomSheet = () => {
    setOpen(false);
    setSelectedDetails(null);
  };

  // Function to calculate days until next vote
  const calculateDaysUntilNextVote = (lastVoteDate) => {
    const currentDate = new Date();
    const nextMonth = new Date(lastVoteDate);

    nextMonth.setMonth(nextMonth.getMonth() + 1);
    nextMonth.setDate(1);

    nextMonth.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    const timeDiff = nextMonth - currentDate;
    const daysRemaining = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    return daysRemaining;
  };

  return (
    <div className='flex min-h-full flex-1 flex-col justify-center px-6 py-5 lg:px-8'>
      <div className='sm:mx-auto sm:w-full sm:max-w-sm md:max-w-2xl'>
        <Header width='size-32' padding='pb-8' />
        <div>
          <p className='text-xl pb-1 md:text-2xl'>Hey Empactors!</p>
          <p className='fontLight text-[#666666] pb-4 leading-snug md:text-xl'>Please select a characteristic that best represent the employee you are voting for </p>
        </div>
        <div className='flex min-h-full flex-col gap-4'>
          {categories.map((category) => (
            <button
              key={category.id}
              className={`flex w-full rounded px-1 text-xl text-white items-center ${category.bgColor} ${category.shadow} ${isDisabled(category) ? 'opacity-35' : 'opacity-100'}`}
              onClick={() => handleClick(category)}
            >
              <img src={category.image} className='size-16 me-3' alt='Category Logo' />
              <p className='fontBold text-base text-left'>{category.name}
                <span className='fontLight xs:text-xs sm:text-base'> - {category.desc}</span>
              </p>
            </button>
          ))}
          <BottomSheet
            open={open}
            onDismiss={() => setOpen(false)}
            snapPoints={({ minHeight }) => minHeight}
          >
            {selectedDetails && (
              <div className='flex flex-col px-5 pb-5'>
                <button
                  className='text-[#666666] bg-gray-100 rounded-2xl text-sm w-8 h-8 ms-auto inline-flex justify-center items-center'
                  onClick={closeBottomSheet}
                >
                  <svg className='w-3 h-3' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 14 14'>
                    <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6' />
                  </svg>
                </button>
                <div>
                  <p className='text-2xl pb-2 md:text-2xl capitalize'>{selectedDetails.category} Characteristic Disabled</p>
                  <p className='fontLight text-[#666666] pb-4 leading-snug md:text-xl'>
                    This category is disabled because you’ve already voted in this category for the month.
                  </p>
                  <p className='fontLight text-[#666666] pb-4 leading-snug md:text-xl'>
                    To ensure fairness, each voter is allowed only one vote per category per month.
                  </p>
                  <p className='fontLight text-[#666666] leading-snug md:text-xl'>
                    We appreciate your participation and encourage you to explore the other categories where you can still vote!
                  </p>
                </div>
                <hr className='h-px my-5 bg-[#E1E1E1] border-0'></hr>
                <div>
                  <p className='text-xl md:text-2xl pb-2 capitalize'>Voting Detail History for {selectedDetails.category} Characteristic</p>
                  <div className='w-full grid grid-col-2 gap-2 pb-2'>
                    <div className='col-span-2 md:col-span-1 flex rounded items-center p-3 bg-[#FDB415]/[.10]'>
                      <div className='p-1 me-3 rounded bg-gradient-to-r from-[#FDB415] from-50% to-[#C78800] to-100% shadow-[0_5px_21px_3px_rgba(193,132,0,0.4)]'>
                        <svg fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke='white' className='size-8'>
                          <path strokeLinecap='round' strokeLinejoin='round' d='M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z' />
                        </svg>
                      </div>
                      <div className='flex flex-col'>
                        <p className='uppercase text-xs md:text-sm'>Date of vote</p>
                        <p className='fontLight text-sm text-[#666666] md:text-base'>{moment(selectedDetails.voteDate).format('YYYY-MM-DD')}</p>
                      </div>
                    </div>

                    <div className='col-span-2 md:col-span-1 flex rounded items-center p-3 bg-[#A9161B]/[.10]'>
                      <div className='p-1 me-3 rounded bg-gradient-to-r from-[#D02127] from-50% to-[#A20000] to-100% shadow-[0px_5px_21px_3px_rgba(169,22,27,0.4)]'>
                        <svg fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke='white' className='size-8'>
                          <path strokeLinecap='round' strokeLinejoin='round' d='M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z' />
                        </svg>
                      </div>
                      <div className='flex flex-col'>
                        <p className='uppercase text-xs md:text-sm'>Name</p>
                        <p className='fontLight text-sm text-[#666666] md:text-base'>{selectedDetails.employeeName}</p>
                      </div>
                    </div>

                    <div className='col-span-2 flex rounded items-start p-3 bg-[#0F8342]/[.10]'>
                      <div className='p-1 me-3 rounded bg-gradient-to-r from-[#0F8342] from-50% to-[#0B6533] to-100% shadow-[0px_5px_21px_3px_rgba(15,131,66,0.4)]'>
                        <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke='white' className='size-8'>
                          <path strokeLinecap='round' strokeLinejoin='round' d='M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z' />
                        </svg>
                      </div>
                      <div className='flex flex-col'>
                        <p className='uppercase text-xs md:text-sm'>Reason</p>
                        <p className='fontLight text-sm text-[#666666] md:text-base'>{selectedDetails.comment}</p>
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-col w-full rounded py-2 text-xl text-white items-center bg-gradient-to-r from-[#12AFBE] from-50% to-[#1196A3] to-100% shadow-[0px_5px_21px_3px_rgba(18,175,190,0.47)]'>
                    <p className='uppercase text-sm fontLight'>Days until you can vote again</p>
                    <p className='text-2xl'>{calculateDaysUntilNextVote(selectedDetails.voteDate)}</p>
                  </div>
                </div>
              </div>
            )}
          </BottomSheet>
        </div>
      </div>
    </div>
  )
};

export default Categories
