import React from 'react';

const Button = ({ children, onClick, bgColor, shadow, padding, color, width, fontSize, justifySelf }) => {
  return (
    <div className='grid'>
        <button
            type='submit'
            className={`flex ${width} justify-center rounded ${bgColor} ${padding} ${fontSize} ${color} ${shadow} ${justifySelf}`}
            onClick={onClick}
        >
        {children}
        </button>
    </div>
  )
}

export default Button