import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider} from 'react-router-dom';

import './index.css';
import 'react-spring-bottom-sheet/dist/style.css';

import Login from './components/pages/login/Login';
import Categories from './components/pages/categories/Categories';
import EmployerDetails from './components/pages/employerDetails/EmployerDetails';

const router = createBrowserRouter([
  {
    path: `${process.env.REACT_APP_HOMEPAGE}`,
    element: <Login />,
    errorElement: <div>404 Not Found</div>
  },
  {
    path: `${process.env.REACT_APP_HOMEPAGE}categories`,
    element: <Categories />
  },
  {
    path: `${process.env.REACT_APP_HOMEPAGE}employerDetails`,
    element: <EmployerDetails />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <RouterProvider router={router} />
  // </React.StrictMode>
);