import React from 'react';
import ourHeritageLogo from '../../assets/ourHeritageLogo.jpg';

const Header = ({ width, padding }) => {
  return (
    <img
        alt='Our Heritage Logo'
        src= {ourHeritageLogo}
        className={`mx-auto h-auto ${width} ${padding}`}
        />
  )
}

export default Header